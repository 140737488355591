/// only apply the contents to devices with reduced motion
///
/// @outputs the content of the mixin wrapped in a proper @media query
///
/// @example
///   @include preferes-reduced-motion {
///     transition: unset;
///   }
@mixin prefers-reduced-motion {
  @media screen and (prefers-reduced-motion: reduce) {
    @content;
  }
}

/// reduces all transitions for reduced motion mode
///
/// @outputs a media query, disabling transitions for devices with reduced motion
///
/// @example
///   @include reduce-transition;
@mixin reduce-transition {
  @include prefers-reduced-motion {
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

/// reduces all animations for reduced motion mode
///
/// @outputs a media query, disabling animations for devices with reduced motion
///
/// @example
///   @include reduce-animation;
@mixin reduce-animation {
  @include prefers-reduced-motion {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}
