
div.pkcp {
  // variable defaults
  
/* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
& {
  // general
  --pkcp-font-size: 15px;
  --pkcp-background-color: #f0f0f0;
  --pkcp-foreground-color: #202122;
  --pkcp-border-color: #858585;
  --pkcp-transition-duration: 0.5s;
  --pkcp-border-radius: 5px;

  // font sizes
  --pkcp-banner-title-font-size: calc(var(--pkcp-font-size) + 3px);
  --pkcp-banner-description-font-size: var(--pkcp-font-size);
  --pkcp-button-font-size: var(--pkcp-font-size);
  --pkcp-popup-header-font-size: calc(var(--pkcp-font-size) + 6px);
  --pkcp-popup-navbar-main-font-size: calc(var(--pkcp-font-size) + 1px);
  --pkcp-popup-navbar-sub-font-size: var(--pkcp-font-size);
  --pkcp-popup-category-title-font-size: calc(var(--pkcp-font-size) + 15px);
  --pkcp-popup-category-description-font-size: var(--pkcp-font-size);
  --pkcp-popup-provider-title-font-size: calc(var(--pkcp-font-size) + 9px);
  --pkcp-popup-section-title-font-size: calc(var(--pkcp-font-size) + 3px);
  --pkcp-popup-section-description-font-size: var(--pkcp-font-size);
  --pkcp-popup-table-header-font-size: calc(var(--pkcp-font-size) + 1px);
  --pkcp-popup-table-body-font-size: var(--pkcp-font-size);

  // backgrounds
  --pkcp-banner-background: var(--pkcp-background-color);
  --pkcp-popup-background: var(--pkcp-background-color);
  --pkcp-overlay-background: rgb(0 0 0 / 50%);
  --pkcp-banner-overlay-background: rgb(0 0 0 / 30%);

  // foregrounds
  --pkcp-banner-text-color: var(--pkcp-foreground-color);
  --pkcp-popup-text-color: var(--pkcp-foreground-color);
  --pkcp-popup-menu-button-color: var(--pkcp-foreground-color);

  // borders
  --pkcp-popup-sidebar-border-color: var(--pkcp-border-color);
  --pkcp-popup-header-border-color: var(--pkcp-border-color);
  --pkcp-popup-footer-border-color: var(--pkcp-border-color);
  --pkcp-horizontal-line-color: var(--pkcp-border-color);

  // border radius
  --pkcp-popup-border-radius: var(--pkcp-border-radius);
  --pkcp-revoke-border-radius: var(--pkcp-border-radius);

  // transitions
  --pkcp-banner-transition-duration: var(--pkcp-transition-duration);
  --pkcp-banner-overlay-transition-duration: var(--pkcp-transition-duration);

  // buttons
  --pkcp-button-accept-background: #317a42;
  --pkcp-button-accept-background-hover: #378f4b;
  --pkcp-button-accept-color: #f0f0f0;
  --pkcp-button-accept-border-width: 0;
  --pkcp-button-accept-border-color: transparent;
  --pkcp-button-settings-background: #317580;
  --pkcp-button-settings-background-hover: #47919d;
  --pkcp-button-settings-color: #f0f0f0;
  --pkcp-button-settings-border-width: 0;
  --pkcp-button-settings-border-color: transparent;
  --pkcp-button-decline-background: #666;
  --pkcp-button-decline-background-hover: #6d6d6d;
  --pkcp-button-decline-color: #f0f0f0;
  --pkcp-button-decline-border-width: 0;
  --pkcp-button-decline-border-color: transparent;
  --pkcp-button-privacy-background: transparent;
  --pkcp-button-privacy-background-hover: transparent;
  --pkcp-button-privacy-color: var(--pkcp-foreground-color, #212529);
  --pkcp-button-privacy-border-width: 1px;
  --pkcp-button-privacy-border-color: var(--pkcp-foreground-color, #212529);
  --pkcp-button-save-background: var(--pkcp-button-settings-background, #378a97);
  --pkcp-button-save-background-hover: var(--pkcp-button-settings-background-hover, #3c9ead);
  --pkcp-button-save-color: #f0f0f0;
  --pkcp-button-save-border-width: 0;
  --pkcp-button-save-border-color: transparent;
  --pkcp-button-abort-background: var(--pkcp-button-decline-background, #858585);
  --pkcp-button-abort-background-hover: var(--pkcp-button-decline-background-hover, #999);
  --pkcp-button-abort-color: #f0f0f0;
  --pkcp-button-abort-border-width: 0;
  --pkcp-button-abort-border-color: transparent;
}



  // theme variables
  
&.theme-light {
  // no rules here, as the light theme currently doesn't differ from the initial styling
}


  
&.theme-dark {
  // general
  --pkcp-background-color: #495057;
  --pkcp-foreground-color: #f0f0f0;
  --pkcp-border-color: #858585;

  // backgrounds
  --pkcp-popup-sidebar-background: #223545;
}



  // utilities
  
.hidden {
  display: none !important;
}



  // components
  
> .pkcp-overlay {
  z-index: 5000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--pkcp-overlay-background, rgb(0 0 0 / 50%));

  // initial state, changed later through script
  display: none;
}


  
> .pkcp-banner {
  z-index: 6000;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  max-height: 99vh;
  max-width: 100vw;
  background-color: var(--pkcp-banner-background, #f8f9fa);
  color: var(--pkcp-banner-text-color, #212529);

  @include breakpoint-md-min {
    flex-direction: row;
  }

  &.position-bottom {
    bottom: -100%;
    box-shadow: 0 1px 15px -1px rgb(0 0 0 / 90%);
    transition: bottom var(--pkcp-banner-transition-duration, 0.3s) ease-in-out;

    &.in {
      bottom: 0 !important;
      transition-duration: 0.5s;
    }
  }

  &.position-top {
    top: -100%;
    box-shadow: 0 -1px 15px -1px rgb(0 0 0 / 90%);
    transition: top var(--pkcp-banner-transition-duration, 0.3s) ease-in-out;

    &.in {
      top: 0 !important;
      transition-duration: 0.5s;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-color: rgb(0 0 0 / 0%);
    transition:
      background-color
      var(--pkcp-banner-overlay-transition-duration, 0.5s) ease;
  }

  &.overlayed {
    &::after {
      background-color:
        var(
          --pkcp-banner-overlay-background,
          rgb(0 0 0 / 30%)
        );
      pointer-events: all;
    }
  }

  @include prefers-reduced-motion {
    transition-duration: 0 !important;
  }

  > .buttons {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 400px;

    @include breakpoint-md-min {
      min-width: 200px;
      width: 10%;
    }

    @include breakpoint-xl-min {
      min-width: 250px;
      width: 15%;
    }

    > a {
      width: 100%;
    }

    button.pkcp-btn {
      width: 100%;

      &:hover {
        transform: scale(1.05);
      }
    }

    > *:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  > .content {
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow-y: auto;
    margin-bottom: 15px;

    @include breakpoint-md-min {
      margin-bottom: 0;
      margin-right: 15px;
    }

    > p {
      margin-bottom: 0;

      > .title {
        &,
        > * {
          font-weight: bold;
          font-size: var(--pkcp-banner-title-font-size, 12pt);
          color: var(--pkcp-banner-text-color, #212529);
        }
      }

      > .description {
        &,
        > * {
          font-size: var(--pkcp-banner-description-font-size, 11pt);
          color: var(--pkcp-banner-text-color, #212529);
        }
      }
    }
  }
}


  
> .pkcp-details {
  z-index: 7000;
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 30px;
  right: 30px;
  margin: 0 auto;
  max-width: 700px;
  min-height: min(100vh, 600px);
  background-color: var(--pkcp-popup-background, #f8f9fa);
  color: var(--pkcp-popup-text-color, #212529);
  border-radius: var(--pkcp-popup-border-radius, 5px);
  box-shadow: 0 0 20px 1px rgb(0 0 0 / 80%);

  // initial state, changed later through script
  display: none;

  @include breakpoint-md-min {
    top: min(10%, 100px);
    bottom: min(10%, 100px);
    left: min(10%, 200px);
    right: min(10%, 200px);
    max-width: 1000px;
  }

  @include breakpoint-lg-min {
    bottom: min(15%, 120px);
    left: min(12%, 200px);
    right: min(12%, 200px);
    max-width: 1200px;
  }

  @include breakpoint-xl-min {
    top: min(10%, 150px);
    left: min(15%, 200px);
    right: min(15%, 200px);
    bottom: min(15%, 150px);
  }

  > .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    
> .header {
  height: 50px;
  padding: 5px;
  padding-left: 1rem;
  border-bottom: 1px solid var(--pkcp-popup-header-border-color);
  z-index: 150;

  > button.navbar-toggle {
    display: inline-block;
    vertical-align: text-top;
    margin-top: 2px;
    margin-right: 7px;
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none !important;

    > div {
      height: 5px;
      width: 25px;
      background-color: var(--pkcp-popup-menu-button-color);
      margin: 3px 0;
      transition: all 0.3s ease;

      @include prefers-reduced-motion {
        transition-duration: 0 !important;
      }

      &:nth-of-type(1) {
        transform: translateY(0) rotate(0deg);
      }

      &:nth-of-type(2) {
        opacity: 1;
      }

      &:nth-of-type(3) {
        transform: translateY(0) rotate(0deg);
      }
    }

    &.open {
      > div {
        &:nth-of-type(1) {
          transform: translateY(8px) rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }

    @include breakpoint-md-min {
      display: none;
    }
  }

  > .title {
    font-size: var(--pkcp-popup-header-font-size, 15pt);
    line-height: calc(var(--pkcp-popup-header-font-size) * 1.9);
    font-weight: bold;
    vertical-align: text-top;
    color: var(--pkcp-popup-text-color, #212529);
  }

  > button.close {
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 22pt;
    transition: opacity 0.3s ease;
    color: var(--pkcp-popup-text-color, #212529);
  }
}


    
> .main {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100% - 175px);
  box-shadow: inset 0 0 5px -2px rgb(0 0 0 / 70%);

  @include breakpoint-md-min {
    height: calc(100% - 100px);
  }

  > * {
    overflow-y: auto;
    height: 100%;
  }

  > nav.sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 5px;
    border-right: 1px solid var(--pkcp-popup-sidebar-border-color);
    width: 30%;
    z-index: 100;
    min-width: 250px;
    max-width: 100%;
    position: absolute;
    background-color:
      var(
        --pkcp-popup-sidebar-background,
        var(--pkcp-popup-background, #f8f9fa)
      );
    left: -100%;
    opacity: 0;
    transition: left 0.3s ease, opacity 0.5s ease;

    @include prefers-reduced-motion {
      transition-duration: 0 !important;
    }

    &.shown {
      opacity: 1;
    }

    &.in {
      left: 0;

      + .content {
        &::before {
          background: rgb(0 0 0 / 30%) !important;
          pointer-events: all !important;
        }
      }
    }

    @include breakpoint-md-min {
      position: initial;
      left: unset !important;
      max-width: 300px;
    }

    ul {
      // padding-left: 30px;
      padding-left: 0;
      list-style: none;

      ul {
        padding-left: 0;
        list-style: none;

        > li {
          position: relative;
          padding-left: 25px;

          > .mock-list-icon {
            position: absolute;
            left: 0;
            top: -1px;
            height: 25px;
            width: 20px;

            > svg {
              fill:
                var(
                  --pkcp-popup-text-color,
                  var(--pkcp-foreground-color, #212529)
                );
              vertical-align: text-top;

              circle,
              ellipse,
              line,
              path,
              polygon,
              polyline,
              rect {
                shape-rendering: geometricprecision;
              }
            }
          }
        }
      }
    }

    li.category {
      margin-bottom: 15px;

      div.title {
        padding-left: 0;
        margin-bottom: 3px;

        > label.form-check-label {
          margin-left: 0;
          margin-bottom: 3px;
          user-select: none;
          font-weight: bold;
          font-size: var(--pkcp-popup-navbar-font-size);

          > .pkcp-checkbox {
            cursor: pointer;
            position: relative;
            display: inline-block;
            vertical-align: sub;
            height: 20px;
            width: 20px;
            background-color: var(--pkcp-checkbox-hover-background, #eee);

            &::after {
              content: "";
              position: absolute;
              display: none;
              left: 6px;
              top: 2px;
              width: 8px;
              height: 13px;
              border: solid #fff;
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          }

          > input.form-check-input {
            display: none;

            &:checked ~ .pkcp-checkbox {
              background-color:
                var(
                  --pkcp-checkbox-checked-background,
                  var(--pkcp-button-settings-background, #378a97)
                );

              &::after {
                display: block;
              }
            }

            &:disabled ~ .pkcp-checkbox {
              cursor: not-allowed;
              background-color:
                var(
                  --pkcp-checkcox-disabled-background,
                  var(--pkcp-button-decline-background, #757575)
                );
            }
          }

          > a {
            color: var(--pkcp-popup-text-color, #212529);
            font-size: var(--pkcp-popup-navbar-main-font-size);
          }

          &:hover {
            /* stylelint-disable-next-line no-descending-specificity */
            > .pkcp-checkbox {
              background-color: var(--pkcp-checkbox-hover-background, #ccc);
            }
          }
        }
      }

      > .subentries {
        > ul {
          > li.provider {
            margin-bottom: 2px;

            > a {
              color: var(--pkcp-popup-text-color, #212529);
              font-size: var(--pkcp-popup-navbar-sub-font-size);

              > span {
                vertical-align: text-top;
              }
            }
          }
        }

        > hr {
          margin-left: -1.5rem;
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  > .content {
    padding: 10px 5px;
    overflow-wrap: break-word;
    word-break: break-word;

    // fade-in animation
    opacity: 0;
    transition: opacity 0.5s ease;

    &.shown {
      opacity: 1;
    }

    .category-page {
      margin-bottom: 11%;

      &:last-of-type {
        margin-bottom: 33%;
      }

      > .title {
        h2 {
          font-size: var(--pkcp-popup-category-title-font-size, 30px);
          font-weight: bold;
          color: var(--pkcp-popup-text-color, #212529);
        }
      }

      > .description {
        p {
          font-size: var(--pkcp-popup-category-description-font-size, 30px);
        }
      }

      > .provider-information {
        > h3 {
          font-size: var(--pkcp-popup-provider-title-font-size, 24px);
          color: var(--pkcp-popup-text-color, #212529);
          margin-bottom: 1em;
        }

        h4 {
          font-size: var(--pkcp-popup-section-title-font-size, 18px);
          color: var(--pkcp-popup-text-color, #212529);
        }

        p {
          font-size: var(--pkcp-popup-section-description-font-size, 30px);
        }

        > .cookies,
        > .session-storage,
        > .local-storage,
        > .indexed-db {
          table.table {
            margin-bottom: 1.5em;

            > thead {
              > tr {
                > th {
                  font-size: var(--pkcp-popup-table-header-font-size, 16px);
                  font-weight: bold;
                }
              }
            }

            > tbody {
              > tr {
                > td {
                  font-size: var(--pkcp-popup-table-body-font-size, 16px);

                  &:nth-of-type(1) {
                    width: 25%;

                    @include breakpoint-lg-min {
                      width: 20%;
                    }
                  }

                  &:nth-of-type(2) {
                    width: 25%;

                    @include breakpoint-lg-min {
                      width: 20%;
                    }
                  }

                  &:nth-of-type(3) {
                    width: 50%;

                    @include breakpoint-lg-min {
                      width: 60%;
                    }
                  }
                }
              }
            }
          }
        }

        > .available-details-notice {
          font-style: italic;
        }
      }

      > hr {
        margin: 25px 0 20px;
      }
    }

    &::before {
      content: " ";
      background: rgb(0 0 0 / 0%);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      transition: all 0.3s ease;
    }
  }

  
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: radial-gradient(rgb(50 50 50 / 70%), rgb(50 50 50 / 30%));

  > .spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    > div {
      position: absolute;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
      animation: pkcp-loading-spinner 1.2s linear infinite;
    }

    > div:nth-child(1) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
    }

    > div:nth-child(2) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
    }

    > div:nth-child(3) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
    }

    > div:nth-child(4) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
    }

    > div:nth-child(5) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
    }

    > div:nth-child(6) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
    }

    > div:nth-child(7) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
    }

    > div:nth-child(8) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
    }

    > div:nth-child(9) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
    }

    > div:nth-child(10) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
    }

    > div:nth-child(11) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
    }

    > div:nth-child(12) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
    }

    @keyframes pkcp-loading-spinner {
      0%,
      20%,
      80%,
      100% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }
    }
  }
}


}


    
> .footer {
  border-top: 1px solid var(--pkcp-popup-footer-border-color);
  height: 130px;
  padding: 5px;
  z-index: 200;
  display: flex;
  flex-direction: column;

  @include breakpoint-md-min {
    height: 50px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  > button.pkcp-btn {
    width: 100%;

    @include breakpoint-md-min {
      width: auto;
      flex-grow: 1;
    }

    &:hover {
      transform: scale(1.02);
    }

    &:not(:last-child) {
      margin-bottom: 5px;

      @include breakpoint-md-min {
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
  }
}



    .table {
      color:
        var(
          --pkcp-popup-text-color,
          var(--pkcp-foreground-color, #212529)
        );
    }

    hr {
      border-color:
        var(
          --pkcp-horizontal-line-color,
          var(--pkcp-border-color, #bbb)
        );
    }
  }
}


  
> .pkcp-revoke {
  z-index: 8000;
  position: fixed;
  width: 50px;
  height: 50px;
  left: 15px;
  bottom: 15px;

  > button.btn {
    padding: 5px 3px;
    background-color: #fff;

    > img {
      max-width: 100%;
      width: auto;

      /* filter: drop-shadow(1px 2px 3px black); */
    }
  }
}


  
button.pkcp-btn {
  font-size: var(--pkcp-button-font-size, 11pt);
  font-weight: bold;
  text-align: center;
  transform: scale(1);
  transition: all 0.3s ease;
  text-rendering: geometricprecision;

  &.pkcp-btn-accept {
    background-color: var(--pkcp-button-accept-background, #317a42);
    color: var(--pkcp-button-accept-color, #fff);
    border: var(--pkcp-button-accept-border-width, 0) solid;
    border-color: var(--pkcp-button-accept-border-color, transparent);

    &:hover {
      background-color: var(--pkcp-button-accept-background-hover, #35a04e);
    }
  }

  &.pkcp-btn-settings {
    background-color: var(--pkcp-button-settings-background, #378a97);
    color: var(--pkcp-button-settings-color, #fff);
    border: var(--pkcp-button-settings-border-width, 0) solid;
    border-color: var(--pkcp-button-settings-border-color, transparent);

    &:hover {
      background-color: var(--pkcp-button-settings-background-hover, #3eadbe);
    }
  }

  &.pkcp-btn-decline {
    background-color: var(--pkcp-button-decline-background, #858585);
    color: var(--pkcp-button-decline-color, #212529);
    border: var(--pkcp-button-decline-border-width, 0) solid;
    border-color: var(--pkcp-button-decline-border-color, transparent);

    &:hover {
      background-color: var(--pkcp-button-decline-background-hover, #919191);
    }
  }

  &.pkcp-btn-privacy {
    background-color: var(--pkcp-button-privacy-background, transparent);
    color: var(--pkcp-button-privacy-color, #212529);
    border: var(--pkcp-button-privacy-border-width, 1px) solid;
    border-color: var(--pkcp-button-privacy-border-color, #212529);

    &:hover {
      background-color:
        var(
          --pkcp-button-privacy-background-hover,
          transparent
        );
    }
  }

  &.pkcp-btn-save {
    background-color:
      var(
        --pkcp-button-save-background,
        var(--pkcp-button-settings-background, #378a97)
      );
    color:
      var(
        --pkcp-button-save-color,
        var(--pkcp-button-settings-color, #fff)
      );
    border:
      var(
        --pkcp-button-save-border-width,
        var(--pkcp-button-settings-border-width, 0)
      )
      solid;
    border-color:
      var(
        --pkcp-button-save-border-color,
        var(--pkcp-button-settings-border-color, transparent)
      );

    &:hover {
      background-color:
        var(
          --pkcp-button-save-background-hover,
          var(--pkcp-button-settings-background-hover, #3eadbe)
        );
    }
  }

  &.pkcp-btn-abort {
    background-color:
      var(
        --pkcp-button-abort-background,
        var(--pkcp-button-decline-background, #858585)
      );
    color:
      var(
        --pkcp-button-abort-color,
        var(--pkcp-button-decline-color, #212529)
      );
    border:
      var(
        --pkcp-button-abort-border-width,
        var(--pkcp-button-decline-border-width, 0)
      )
      solid;
    border-color:
      var(
        --pkcp-button-abort-border-color,
        var(--pkcp-button-decline-border-color, transparent)
      );

    &:hover {
      background-color:
        var(
          --pkcp-button-abort-background-hover,
          var(--pkcp-button-decline-background-hover, #919191)
        );
    }
  }
}



  // theme styling
  
&.theme-light {

}


  
&.theme-dark {

}


  
&.theme-default {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .theme-dark;
}



  // prevent any user-selection inside the popup
  user-select: none !important;
}


